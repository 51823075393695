import React, { Component } from "react";

class Section7Mobile extends Component {
  state = {
    translateLeft: 0,
    translateLeftPropertie: 0,
    leftOpacity: 0,
  };
  componentDidMount() {
    this.setState({
      translateLeft: -this.props.wd.sh * 0.7,
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.pagescrolled !== prevProps.pagescrolled) {
      var st = this.props.pagescrolled;
      this.setTranslate(st);
    }
  }
  setTranslate = (scroll) => {
    var ele = document.getElementById("section7");
    var TranslateLeft;
    var TranslateLeftPropertie;
    var LeftOpacity;
    var startAnimFactor = 0.3 * this.props.wd.wh;

    var ele_offsettop = ele.offsetTop;
    /*** set opacity Animation */
    var sectionScrollPercentageWithFactor =
      (100 * (scroll - (ele_offsettop - startAnimFactor))) / ele.clientHeight;
    if (scroll > ele_offsettop - startAnimFactor) {
      LeftOpacity =
        sectionScrollPercentageWithFactor * 0.05 < 1
          ? sectionScrollPercentageWithFactor * 0.05
          : 1;
    } else {
      LeftOpacity = 0;
    }
    this.setState({
      leftOpacity: LeftOpacity,
    });
    /*** set translate left Animation */
    TranslateLeft =
      this.state.translateLeft +
      (scroll - ele_offsettop + this.props.navheight);
    if (TranslateLeft > 0) {
      TranslateLeftPropertie = "translate3d(0px, 0px, 0px)";
    } else {
      TranslateLeftPropertie = "translate3d(0px," + TranslateLeft + "px, 0px)";
    }
    this.setState({
      translateLeftPropertie: TranslateLeftPropertie,
    });
  };
  render() {
    return (
      <div
        id="section7"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
          height: this.props.wd.sh,
        }}
      >
        <div className="column left">
          <div className="content">
            <img
              alt="Imagem Título localização"
              className="title transitionY_animation"
              style={{ transform: this.state.translateLeftPropertie }}
              src="images/section7_whitetitle.png"
            />
            <div className="text_content">
              <p>Para qualquer esclarecimento contacte-nos:</p>
              <div className="contacts_containers phone_content">
                <img alt="Imagem phone icone" src="images/logo_phone.png" />
                <a href="tel:+351912345678">
                  <p>+351 912345678</p>
                </a>
              </div>
              <div className="contacts_containers email_content">
                <img alt="Imagem email icone" src="images/logo_email.png" />
                <a href="mailto:info@nlbjjacademy.com">
                  <p>info@nlbjjacademy.com</p>
                </a>
              </div>
              <p>
                E se nos quiser conhecer melhor sem sair do sofá vá às nossas
                redes sociais. Se ainda não conseguimos, esperemos que o
                cativem.
              </p>
              <div className="social_containers">
                <div className="social_container facebook_container">
                  <img
                    alt="Imagem facebook icone"
                    src="images/logo_facebook.png"
                  />
                </div>
                <div className="social_container instagram_container">
                  <img
                    alt="Imagem instagram ícone"
                    src="images/logo_instagram.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="column right"
          style={{
            backgroundImage: "url(images/section7_background1.jpg)",
          }}
        >
          <div
            className="backgroundImage opacity_animation"
            style={{
              backgroundImage: "url(images/section7_background2.jpg)",
              opacity: this.state.rightOpacity,
            }}
          ></div>
          <div className="content">
            <img
              alt="Imagem Título contacte-nos"
              className="title"
              style={{ transform: "translate3d(0px, 0px, 0px)" }}
              src="images/section7_blacktitle.png"
            />
            <div className="text_content">
              <p>
                Se preferir também pode contactar-nos directamente por aqui.
                Entraremos em contacto assim que possível.
              </p>
              <form>
                <input type="text" placeholder="nome*" />
                <input type="text" placeholder="nome*" />
                <textarea placeholder="mensagem*" />
                <button type="submit">
                  <div className="btn from-bottom">
                    <p>ENVIAR</p>
                  </div>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Section7Mobile;
