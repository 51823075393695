import React, { Component } from "react";

class Section1 extends Component {
  state = {
    leftOpacity: 0,
    rightOpacity: 0,
    translateRight: 0,
    translateRightPropertie: 0,
  };
  componentDidUpdate(prevProps) {
    if (this.props.pagescrolled !== prevProps.pagescrolled) {
      var st = this.props.pagescrolled;
      this.scrollAnimations(st);
    }
  }
  scrollAnimations = (scroll) => {
    var ele = document.getElementById("section1");
    var LeftOpacity;
    var RightOpacity;
    var TranslateRight;
    var TranslateRightPropertie;

    var ele_offsettop = ele.offsetTop;
    /*** Opacities animation */
    var scrollPercentage = (100 * this.props.pagescrolled) / ele.clientHeight;
    LeftOpacity = scrollPercentage * 0.05 < 1 ? scrollPercentage * 0.05 : 1;
    RightOpacity = scrollPercentage * 0.1 < 0.5 ? scrollPercentage * 0.1 : 0.5;
    this.setState({
      leftOpacity: LeftOpacity,
    });
    this.setState({
      rightOpacity: RightOpacity,
    });

    /*** translate Right animation */
    TranslateRight =
      this.state.translateRight +
      (scroll - ele_offsettop + this.props.navheight);
    TranslateRightPropertie = "translate3d(0px," + TranslateRight + "px, 0px)";
    this.setState({
      translateRightPropertie: TranslateRightPropertie,
    });
  };
  render() {
    return (
      <div
        id="section1"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
          height: this.props.wd.sh,
          marginTop: this.props.navheight,
          backgroundImage: "url(images/equipa.jpg)",
        }}
      >
        <div
          className="column left"
          style={{
            backgroundColor: `rgba(0, 0, 0, ${this.state.leftOpacity})`,
          }}
        >
          <img
            alt="Jiu Jiteiros"
            className="pawn opacity_animation"
            src="images/pawn1.png"
            style={{
              opacity: this.state.leftOpacity,
            }}
          />
        </div>
        <div
          className="column right"
          style={{
            backgroundColor: `rgba(255, 255, 255, ${this.state.rightOpacity})`,
          }}
        >
          <div className="content">
            <img
              alt="Título Jiu Jitsu"
              className="title transitionY_animation opacity_animation"
              src="images/section1_blacktitle.png"
              style={{
                opacity: this.state.leftOpacity,
                transform: this.state.translateRightPropertie,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Section1;
