import React, { Component } from "react";

class Section5Mobile extends Component {
  state = {
    translateLeft: 0,
    leftOpacity: 0,
  };
  componentDidMount() {
    this.setState({
      translateLeft: -this.props.wd.sh * 0.7,
    });
  }
  render() {
    return (
      <div
        className="section content_container"
        style={{
          width: this.props.wd.ww,
          height: this.props.wd.sh,
        }}
      >
        <div
          className="column left"
          style={{
            backgroundColor: `rgba(0, 0, 0, 1)`,
          }}
        >
          <img
            alt="Imagem Jiu Jiteiros"
            className="pawn opacity_animation"
            src="images/pawn3.png"
            style={{
              opacity: 1,
            }}
          />
        </div>
        <div
          id="section5"
          className="column right"
          style={{
            backgroundColor: `rgba(255, 255, 255, 1)`,
          }}
        >
          <div className="content">
            <img
              alt="Imagem Título Horário"
              className="title"
              src="images/section5_blacktitle.png"
            />
            <div className="text_content">
              <div className="schedule">
                <div className="row schedule-title">
                  <div className="col-2">
                    <p>SEG</p>
                  </div>
                  <div className="col-2">
                    <p>TER</p>
                  </div>
                  <div className="col-2">
                    <p>QUA</p>
                  </div>
                  <div className="col-2">
                    <p>QUI</p>
                  </div>
                  <div className="col-2">
                    <p>SEX</p>
                  </div>
                  <div className="col-2">
                    <p>SAB</p>
                  </div>
                </div>
                <div className="row schedule-box-morning no_margin">
                  <div className="col-2">
                    <div className="box">
                      <div className="text-content-box">
                        <p>9:00h 10:30h</p>
                        <p className="box-title">
                          <b>BJJ TEAM</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-2"></div>
                  <div className="col-2">
                    <div className="box">
                      <div className="text-content-box">
                        <p>9:00h 10:30h</p>
                        <p className="box-title">
                          <b>BJJ TEAM</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-2"></div>
                  <div className="col-2">
                    <div className="box">
                      <div className="text-content-box">
                        <p>9:00h 10:30h</p>
                        <p className="box-title">
                          <b>BJJ TEAM</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-2"></div>
                </div>
                <div className="row schedule-gap"></div>
                <div className="row schedule-box-kids">
                  <div className="col-2"></div>
                  <div className="col-2">
                    <div className="box">
                      <div className="text-content-box">
                        <p>18:15h 19:00h</p>
                        <p className="box-title">
                          <b>BJJ KIDS</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-2"></div>
                  <div className="col-2">
                    <div className="box">
                      <div className="text-content-box">
                        <p>18:15h 19:00h</p>
                        <p className="box-title">
                          <b>BJJ KIDS</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-2"></div>
                  <div className="col-2"></div>
                </div>
                <div className="row schedule-box-night">
                  <div className="col-2">
                    <div className="box">
                      <div className="text-content-box">
                        <p>20:30h 22:00h</p>
                        <p className="box-title">
                          <b>BJJ TEAM</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="box">
                      <div className="text-content-box">
                        <p>19:00h 20:00h</p>
                        <p className="box-title">
                          <b>BJJ TEAM</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="box">
                      <div className="text-content-box">
                        <p>20:30h 22:00h</p>
                        <p className="box-title">
                          <b>BJJ TEAM</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="box">
                      <div className="text-content-box">
                        <p>19:00h 20:00h</p>
                        <p className="box-title">
                          <b>BJJ TEAM</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="box">
                      <div className="text-content-box">
                        <p>20:30h 22:00h</p>
                        <p className="box-title">
                          <b>BJJ TEAM</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="box saturday">
                      <div className="text-content-box">
                        <p>17:00h 18:30h</p>
                        <p className="box-title">
                          <b>BJJ TEAM</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Section5Mobile;
