import React, { Component } from "react";
import SlideEvents1 from "../layout/SlideEvents1";

class Section3Academy extends Component {
  render() {
    return (
      <div
        id="Section3Academy"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
          height: this.props.wd.sh / 2,
          marginTop: this.props.wd.sh / 2,
        }}
      >
        <div
          id="section2_anchor"
          className="column left"
          style={{
            height: this.props.wd.sh,
            marginTop: -this.props.wd.sh / 2,
          }}
        >
          <SlideEvents1 openVideoModal={this.props.openVideoModal} />
        </div>
        <div className="column right" style={{ height: this.props.wd.sh / 2 }}>
          <div className="content">
            <img
              alt="Imagem do Título Graduação"
              src="images/section3academy_righttitle.png"
            />
            <p>
              Horus BJJ foi um mini campeonato realizado, não há muito tempo,
              pelo professor Nelson Lopes. Produzido nas instalações dos
              Bombeiros de Alcabideche, foi pequeno e familiar mas foi um
              sucesso! Mais um dia bem passado em equipa.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Section3Academy;
