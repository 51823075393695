import React, { Component } from "react";

class Section9AcademyMobile extends Component {
  render() {
    return (
      <div
        id="Section9Academy"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
        }}
      >
        <div
          id="section6_anchor"
          className="column left"
          style={{
            height: this.props.wd.sh,
          }}
        >
          <div className="content">
            <img
              alt="Imagem Título contactos"
              className="title"
              src="images/section9academy_lefttitle.png"
            />
            <div className="text_content">
              <p>
                Se preferir também pode contactar-nos directamente por aqui.
                Entraremos em contacto assim que possível.
              </p>
              <form>
                <input type="text" placeholder="nome*" />
                <input type="text" placeholder="nome*" />
                <textarea placeholder="mensagem*" />
                <button type="submit">
                  <div className="btn from-bottom">
                    <p>ENVIAR</p>
                  </div>
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="column right" style={{ height: this.props.wd.sh / 2 }}>
          <div className="content">
            <img
              alt="Imagem Título contacte-nos"
              className="title"
              src="images/section9academy_righttitle.png"
            />
            <div className="contacts_containers phone_content">
              <img alt="Imagem phone ícone" src="images/logo_phone_black.png" />
              <a href="tel:+351912345678">
                <p>+351 912345678</p>
              </a>
            </div>
            <div className="contacts_containers email_content">
              <img alt="Imagem email ícone" src="images/logo_email_black.png" />
              <a href="mailto:info@nlbjjacademy.com">
                <p>info@nlbjjacademy.com</p>
              </a>
            </div>
            <div className="social_containers">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/poundforpoundcascais/"
              >
                <div className="social_container facebook_container">
                  <img
                    alt="Imagem facebook ícone"
                    src="images/logo_facebook_white.png"
                  />
                </div>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/p4p_alcabideche/"
              >
                <div className="social_container instagram_container">
                  <img
                    alt="Imagem instagram ícone"
                    src="images/logo_instagram_white.png"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Section9AcademyMobile;
