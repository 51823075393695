import React, { Component } from "react";
import $ from "jquery";
import Navigation from "../layout/Navigation";
import Section1 from "../sections/Section1";
import Section2 from "../sections/Section2";
import Section3 from "../sections/Section3";
import Section4 from "../sections/Section4";
import Section5 from "../sections/Section5";
import Section6 from "../sections/Section6";
import Section7 from "../sections/Section7";

class App extends Component {
  state = {
    wd: {
      ww: 0,
      wh: 0,
      sh: 0,
    },
    navheight: 0,
    scrolled: 0,
  };
  componentDidMount() {
    var st = Math.round(window.pageYOffset);
    this.setState({
      scrolled: st,
    });
    this.updateWindowDimensions();
    this.hashLinkScroll();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    window.removeEventListener("scroll", this.handleScroll);
  }
  updateWindowDimensions = () => {
    var section_height = window.innerHeight - this.state.navheight + 1;
    let wd = {
      ww: window.innerWidth,
      wh: window.innerHeight,
      sh: section_height,
    };
    this.setState({ wd: wd });
  };
  handleScroll = () => {
    var st = Math.round(window.pageYOffset);
    this.setState({
      scrolled: st,
    });
  };
  handleNavHeight = (el_height) => {
    this.setState({ navheight: el_height / 2 });
    let wd = {
      ww: window.innerWidth,
      wh: window.innerHeight,
      sh: window.innerHeight - el_height / 2 + 1,
    };
    this.setState({ wd: wd });
  };
  hashLinkScroll = () => {
    let hash = window.location.hash;
    if (hash != "") {
      setTimeout(() => {
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top,
          },
          500
        );
      }, 0);
    }
  };
  render() {
    return (
      <div
        className="big_container"
        style={{ width: this.state.wd.ww, height: this.state.wd.wh }}
      >
        <Navigation wd={this.state.wd} handleNavHeight={this.handleNavHeight} />
        <div className="main_container">
          <Section1
            navheight={this.state.navheight}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section2
            navheight={this.state.navheight}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section3
            navheight={this.state.navheight}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section4
            navheight={this.state.navheight}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section5
            navheight={this.state.navheight}
            className="schedule"
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section6
            navheight={this.state.navheight}
            className="localization"
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section7
            navheight={this.state.navheight}
            className="contacts"
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
        </div>
      </div>
    );
  }
}

export default App;
