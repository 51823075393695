import React, { Component } from "react";
import SlideProfessor from "../layout/SlideProfessor";

class Section6AcademyMobile extends Component {
  render() {
    return (
      <div
        id="Section6Academy"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
        }}
      >
        <div id="section3_anchor" className="column left section_dif_mobile">
          <div className="content">
            <img
              alt="Imagem do Título Academia"
              className="title"
              src="images/section6academy_lefttitle.png"
            />
            <p>
              Aqui fica mais uma tentativa de ficar a conhecer melhor o
              Professor desta equipa. Com alguns videos de eventos e técnicas,
              já tem algum reportório, no entanto, não há nada como vir
              conhecê-lo pessoalmente.
            </p>
          </div>
        </div>
        <div className="column right" style={{ height: this.props.wd.sh }}>
          <SlideProfessor openVideoModal={this.props.openVideoModal} />
        </div>
      </div>
    );
  }
}

export default Section6AcademyMobile;
