import React, { Component } from "react";
import BootstrapCarousel from "./BootstrapCarousel";

class SlideAcademy extends Component {
  state = {
    controls: true,
    indicators: false,
    pauseOnHover: false,
    interval: 3000,
    nextIcon: <img alt="seta slide esquerda" src="images/arrow_right.png" />,
    prevIcon: <img alt="seta slide direita" src="images/arrow_left.png" />,
    slides: [
      {
        id: 0,
        idSlide: "slide6_img1",
        imgSrc: "images/slide6_img1.png",
        altImg: "First slide Sixth caroussel",
        video: "https://www.youtube.com/embed/ZiHcRZ127Jw?autoplay=1",
      },
      {
        id: 1,
        idSlide: "slide6_img2",
        imgSrc: "images/slide6_img2.png",
        altImg: "Second slide Sixth caroussel",
      },
      {
        id: 2,
        idSlide: "slide6_img3",
        imgSrc: "images/slide6_img3.png",
        altImg: "Third slide Sixth caroussel",
      },
      {
        id: 3,
        idSlide: "slide6_img4",
        imgSrc: "images/slide6_img4.png",
        altImg: "Fourth slide Sixth caroussel",
        video: "https://www.youtube.com/embed/04SuDzaEHFg?autoplay=1",
      },
      {
        id: 4,
        idSlide: "slide6_img5",
        imgSrc: "images/slide6_img5.png",
        altImg: "Fifth slide Sixth caroussel",
      },
      {
        id: 5,
        idSlide: "slide6_img6",
        imgSrc: "images/slide6_img6.png",
        altImg: "Sixth slide Sixth caroussel",
      },
      {
        id: 6,
        idSlide: "slide6_img7",
        imgSrc: "images/slide6_img7.png",
        altImg: "Seven slide Sixth caroussel",
        video: "https://www.youtube.com/embed/onCc5A_tJ7c?autoplay=1",
      },
      {
        id: 7,
        idSlide: "slide6_img8",
        imgSrc: "images/slide6_img8.png",
        altImg: "Eigth slide Sixth caroussel",
      },
      {
        id: 8,
        idSlide: "slide6_img9",
        imgSrc: "images/slide6_img9.png",
        altImg: "Nine slide Sixth caroussel",
      },
      {
        id: 9,
        idSlide: "slide6_img10",
        imgSrc: "images/slide6_img10.png",
        altImg: "Ten slide Sixth caroussel",
      },
    ],
  };
  render() {
    const {
      controls,
      indicators,
      pauseOnHover,
      interval,
      slides,
      nextIcon,
      prevIcon,
    } = this.state;
    return (
      <div className="slide_container">
        <BootstrapCarousel
          controls={controls}
          indicators={indicators}
          pauseOnHover={pauseOnHover}
          interval={interval}
          slides={slides}
          nextIcon={nextIcon}
          prevIcon={prevIcon}
          openVideoModal={this.props.openVideoModal}
        />
        <div className="slide_hover"></div>
      </div>
    );
  }
}

export default SlideAcademy;
