import React, { Component } from "react";

class Section3 extends Component {
  state = {
    translateLeft: 0,
    translateLeftPropertie: 0,
    leftOpacity: 0,
    rigthOpacity: 0,
  };
  componentDidMount() {
    this.setState({
      translateLeft: -this.props.wd.sh * 0.7,
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.pagescrolled !== prevProps.pagescrolled) {
      var st = this.props.pagescrolled;
      this.setTranslate(st);
    }
  }
  setTranslate = (scroll) => {
    var ele = document.getElementById("section3");
    var TranslateLeft;
    var TranslateLeftPropertie;
    var LeftOpacity;
    var RigthOpacity;
    var startAnimFactor = 0.3 * this.props.wd.wh;

    var ele_offsettop = ele.offsetTop;
    /*** set translate left Animation */
    TranslateLeft =
      this.state.translateLeft +
      (scroll - ele_offsettop + this.props.navheight);

    TranslateLeftPropertie = "translate3d(0px," + TranslateLeft + "px, 0px)";

    this.setState({
      translateLeftPropertie: TranslateLeftPropertie,
    });
    /*** set opacity Animation */
    var sectionScrollPercentageWithFactor =
      (100 * (scroll - (ele_offsettop - startAnimFactor))) / ele.clientHeight;
    if (scroll > ele_offsettop - startAnimFactor) {
      LeftOpacity =
        sectionScrollPercentageWithFactor * 0.05 < 1
          ? sectionScrollPercentageWithFactor * 0.05
          : 1;
      RigthOpacity =
        1 - sectionScrollPercentageWithFactor * 0.05 > 0
          ? 1 - sectionScrollPercentageWithFactor * 0.05
          : 0;
    } else {
      LeftOpacity = 0;
      RigthOpacity = 1;
    }
    this.setState({
      leftOpacity: LeftOpacity,
    });
    this.setState({
      rigthOpacity: RigthOpacity,
    });
  };
  render() {
    return (
      <div
        id="section3"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
          height: this.props.wd.sh,
        }}
      >
        <div className="column left">
          <div className="content">
            <img
              alt="Título Professor"
              className="title opacity_animation"
              src="images/section3_whitetitle.png"
              style={{ opacity: this.state.leftOpacity }}
            />
            <p
              className="transitionY_animation"
              style={{ transform: this.state.translateLeftPropertie }}
            >
              O Professor Nelson Lopes iniciou o seu percurso no jiu jitsu em
              2004 com o professor Fábio Fetter. No decorrer do seu percurso,
              sempre competiu e conseguiu alcançar diversos títulos a nivel
              nacional.
              <br />A faixa preta conquistou-a em 2014 e foi a partir desta
              altura que começou a considerar traçar um caminho no jiu jitsu,
              não só como atleta mas também, como Professor desta modalidade.
              Actualmente, e já desde 2015, ensina nos bombeiros de Alcabideche.
            </p>
          </div>
        </div>
        <div
          className="column right"
          style={{
            backgroundImage: "url(images/section3_background.jpg)",
          }}
        >
          <div
            className="backgroundImage"
            style={{
              backgroundColor: `rgba(255, 255, 255, ${this.state.rigthOpacity})`,
            }}
          ></div>
          <img
            alt="Jiu Jiteiros"
            className="pawn opacity_animation"
            src="images/pawn2.png"
            style={{
              opacity: this.state.rigthOpacity,
            }}
          />
        </div>
      </div>
    );
  }
}

export default Section3;
