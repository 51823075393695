import React, { Component } from "react";
import { Carousel } from "react-bootstrap";

class BootstrapCarousel extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      controls: this.props.controls,
      indicators: this.props.indicators,
      pauseOnHover: this.props.pauseOnHover,
      interval: this.props.interval,
      nextIcon: this.props.nextIcon,
      prevIcon: this.props.prevIcon,
      index: 0,
      direction: null,
      slides: this.props.slides,
      carouselItemCount: this.props.slides.length,
    };
  }
  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  }
  render() {
    const {
      index,
      direction,
      controls,
      indicators,
      pauseOnHover,
      interval,
      nextIcon,
      prevIcon,
    } = this.state;
    return (
      <Carousel
        touch={true}
        activeIndex={index}
        direction={direction}
        onSelect={this.handleSelect}
        controls={controls}
        indicators={indicators}
        pauseOnHover={pauseOnHover}
        interval={interval}
        nextIcon={nextIcon}
        prevIcon={prevIcon}
      >
        {this.state.slides.map((slide) => (
          <Carousel.Item key={slide.id} id={slide.idSlide}>
            {slide.video ? (
              <div className="video_container w-100 h-100">
                <img
                  className="d-block"
                  src={slide.imgSrc}
                  alt={slide.altImg}
                />
                <div
                  className="play_video"
                  onClick={this.props.openVideoModal.bind(this, slide.video)}
                >
                  <img src="images/play_button.png" alt="play video" />
                </div>
              </div>
            ) : (
              <div className="image_container w-100 h-100">
                <img
                  className="d-block"
                  src={slide.imgSrc}
                  alt={slide.altImg}
                />
              </div>
            )}
            {slide.caption ? (
              <Carousel.Caption>
                <h1>{slide.captionTitle}</h1>
                {slide.captionText !== "undefined" ? (
                  <p>{slide.captionText}</p>
                ) : null}
              </Carousel.Caption>
            ) : null}
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }
}
export default BootstrapCarousel;
