import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Navigation.css";
import $ from "jquery";

class Navigation extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.checkScroll);
    this.handleNavHeight();
  }
  handleNavHeight = () => {
    const navheight = this.navElement.offsetHeight;
    this.props.handleNavHeight(navheight);
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", this.checkScroll);
  }
  wScroll = (ele) => {
    return $(window).scrollTop();
  };
  checkScroll = (ele) => {
    var links = document.querySelectorAll("nav li > .nav_ancor"),
      offsets = [],
      id;
    var self = this;
    links.forEach(function (i, v) {
      id = $(i).attr("href").replace("#", "");

      offsets.push($("#" + id).offset().top - self.props.wd.wh / 2);
      if (v === links.length - 1) {
        self.toggleNav(offsets);
      }
    });
  };
  toggleNav = (offsets) => {
    var $header = $(".header"),
      $nav = $("nav"),
      _active = "active";
    var self = this;
    $.each(offsets, function (i, v) {
      if (self.wScroll() < offsets[0]) {
        $nav.find("." + _active).removeClass(_active);
        $header.find("b").css({
          opacity: 0,
        });
      }
      if (
        (self.wScroll() > v && self.wScroll() < offsets[i + 1]) ||
        (self.wScroll() > v && offsets[i + 1] == null)
      ) {
        var $elem = $nav.find("li > a:eq(" + i + ")"),
          elemWidth = $elem.outerWidth(),
          elemOffset = $elem.offset().left;

        $nav.find("." + _active).removeClass(_active);
        $elem.addClass(_active);

        $header.find("b").removeClass("invisible");
        $header.find("b").css({
          left: elemOffset + elemWidth / 2,
          opacity: 1,
        });
      }
    });
  };
  handleClickNavLinks = (e) => {
    e.preventDefault();
    var nav_el = document.querySelector(".header");
    var id = e.target.getAttribute("href").replace("#", "");
    var offset = $("#" + id).offset().top - nav_el.offsetHeight + 1;

    $("html, body").animate(
      {
        scrollTop: offset,
      },
      500
    );
    if ($(".header").hasClass("open-mobile")) $("#bt-menu").click();
    return false;
  };

  render() {
    return (
      <div
        className="header"
        onChange={this.handleNavHeight}
        ref={(navElement) => {
          this.navElement = navElement;
        }}
      >
        <div className="container">
          <nav>
            <ul>
              <li style={{ marginLeft: "20px" }}>
                <a
                  className="nav_ancor"
                  href="section2"
                  onClick={this.handleClickNavLinks}
                >
                  JIU JITSU
                </a>
              </li>
              <li>
                <a
                  className="nav_ancor"
                  href="section3"
                  onClick={this.handleClickNavLinks}
                >
                  PROFESSOR
                </a>
              </li>
              <li>
                <a
                  className="nav_ancor"
                  href="section4"
                  onClick={this.handleClickNavLinks}
                >
                  ACADEMIA
                </a>
              </li>
              <li className="logo">
                <div>
                  <Link to="/academia">
                    <img
                      alt="logo Nelson Lopes Academy"
                      src="images/logo_nelson.svg"
                    />
                  </Link>
                </div>
              </li>
              <li>
                <a
                  className="nav_ancor"
                  href="section5"
                  onClick={this.handleClickNavLinks}
                >
                  HORÁRIOS
                </a>
              </li>
              <li>
                <a
                  className="nav_ancor"
                  href="section6"
                  onClick={this.handleClickNavLinks}
                >
                  LOCALIZAÇÃO
                </a>
              </li>
              <li>
                <a
                  className="nav_ancor"
                  href="section7"
                  onClick={this.handleClickNavLinks}
                >
                  CONTACTOS
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <b></b>
      </div>
    );
  }
}

export default Navigation;
