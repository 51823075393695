import React, { Component } from "react";

class Section2 extends Component {
  state = {
    translateRight: 0,
    translateLeft: 0,
    translateRightPropertie: 0,
    translateLeftPropertie: 0,
    leftOpacity: 0,
  };
  componentDidMount() {
    this.setState({
      translateRight: -this.props.wd.sh * 0.7,
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.pagescrolled !== prevProps.pagescrolled) {
      var st = this.props.pagescrolled;
      this.scrollAnimations(st);
    }
  }
  scrollAnimations = (scroll) => {
    var ele = document.getElementById("section2");
    var TranslateRight;
    var TranslateRightPropertie;
    var TranslateLeft;
    var TranslateLeftPropertie;
    var LeftOpacity;
    var startAnimFactor = 0.3 * this.props.wd.wh;

    var ele_offsettop = ele.offsetTop;
    /*** set opacity Animation */
    var sectionScrollPercentageWithFactor =
      (100 * (scroll - (ele_offsettop - startAnimFactor))) / ele.clientHeight;
    if (scroll > ele_offsettop - startAnimFactor) {
      LeftOpacity =
        sectionScrollPercentageWithFactor * 0.05 < 1
          ? sectionScrollPercentageWithFactor * 0.05
          : 1;
    } else {
      LeftOpacity = 0;
    }

    this.setState({
      leftOpacity: LeftOpacity,
    });
    /*** set translate right Animation */
    TranslateRight =
      this.state.translateRight +
      (scroll - ele_offsettop + this.props.navheight);
    TranslateRightPropertie = "translate3d(0px," + TranslateRight + "px, 0px)";

    if (TranslateRight > 0) {
      TranslateRightPropertie = "translate3d(0px, 0px, 0px)";
    } else {
      TranslateRightPropertie =
        "translate3d(0px," + TranslateRight + "px, 0px)";
    }
    this.setState({ translateRightPropertie: TranslateRightPropertie });
    /*** set translate left Animation */
    TranslateLeft =
      this.state.translateLeft +
      (scroll - ele_offsettop + this.props.navheight);

    if (TranslateLeft >= 0) {
      TranslateLeftPropertie = "translate3d(0px," + TranslateLeft + "px, 0px)";
    } else {
      TranslateLeftPropertie = "translate3d(0px, 0px, 0px)";
    }
    this.setState({ translateLeftPropertie: TranslateLeftPropertie });
  };
  render() {
    return (
      <div
        id="section2"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
          height: this.props.wd.sh,
        }}
      >
        <div
          className="column left"
          style={{ backgroundImage: "url(images/section2_background1.jpg)" }}
        >
          <div
            className="backgroundImage opacity_animation"
            style={{
              backgroundImage: "url(images/section2_background2.jpg)",
              opacity: this.state.leftOpacity,
            }}
          ></div>
          <div className="content">
            <img
              alt="Título Jiu Jitsu"
              className="title transitionY_animation"
              src="images/section2_blacktitle.png"
            />
            <p
              className="transitionY_animation"
              style={{ transform: this.state.translateLeftPropertie }}
            >
              Etimologicamente, a expressão japonesa jiu jitsu pode ser
              traduzida literalmente como “arte da suavidade” ou “técnica da
              brandura”, uma referência ao caráter e filosofia desta arte
              marcial: o equilíbrio e o controle total do corpo.
              <br /> O Jiu Jitsu aumenta a confiança, ajuda a emagrecer, reduz o
              stress, melhora a coordenação motora e trabalha o corpo de forma
              completa.
            </p>
          </div>
        </div>
        <div
          className="column right"
          style={{
            backgroundColor: `rgba(0, 0, 0, 1)`,
          }}
        >
          <div className="content">
            <img
              alt="Título Xadrez Humano"
              className="title transitionY_animation"
              src="images/section2_whitetitle.png"
              style={{
                transform: this.state.translateRightPropertie,
              }}
            />
            <img
              alt="Subtítulo Xadrez Humano"
              className="subtitle"
              src="images/section2_whitesubtitle.png"
            />
            <p>
              O jiu jitsu é uma arte marcial originalmente utilizada nas
              guerras, onde o guerreiro usa a sua força através de golpes que
              envolvem as articulações, estrangulamentos, imobilizações, torções
              e alavancas no corpo do adversário.
              <br /> No Brasil, o jiu jitsu ganhou um estilo específico,
              conhecido como BJJ (Brazilian Jiu Jitsu), um esporte de combate de
              luta e autodefesa que surgiu no começo do século XX, a partir do
              Judô.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Section2;
