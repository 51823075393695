import React, { Component } from "react";
import BootstrapCarousel from "./BootstrapCarousel";

class SlideChampionships extends Component {
  state = {
    controls: true,
    indicators: true,
    pauseOnHover: false,
    interval: 3000,
    nextIcon: <img alt="seta slide esquerda" src="images/arrow_right.png" />,
    prevIcon: <img alt="seta slide direita" src="images/arrow_left.png" />,
    slides: [
      {
        id: 0,
        idSlide: "slice1_slide1",
        imgSrc: "images/slide1_img1.png",
        altImg: "First slide First caroussel",
      },
      {
        id: 1,
        idSlide: "slice1_slide2",
        imgSrc: "images/slide1_img2.png",
        altImg: "Second slide First caroussel",
      },
      {
        id: 2,
        idSlide: "slice1_slide3",
        imgSrc: "images/slide1_img3.png",
        altImg: "Third slide First caroussel",
      },
      {
        id: 3,
        idSlide: "slice1_slide4",
        imgSrc: "images/slide1_img4.png",
        altImg: "Fourth slide First caroussel",
      },
      {
        id: 4,
        idSlide: "slice1_slide5",
        imgSrc: "images/slide1_img5.png",
        altImg: "Fifth slide First caroussel",
      },
      {
        id: 5,
        idSlide: "slice1_slide6",
        imgSrc: "images/slide1_img6.png",
        altImg: "Sixth slide First caroussel",
      },
      {
        id: 6,
        idSlide: "slice1_slide7",
        imgSrc: "images/slide1_img7.png",
        altImg: "Seven slide First caroussel",
      },
      {
        id: 7,
        idSlide: "slice1_slide8",
        imgSrc: "images/slide1_img8.png",
        altImg: "Eigth slide First caroussel",
      },
      {
        id: 8,
        idSlide: "slice1_slide9",
        imgSrc: "images/slide1_img9.png",
        altImg: "Nineth slide First caroussel",
      },
      {
        id: 9,
        idSlide: "slice1_slide10",
        imgSrc: "images/slide1_img10.png",
        altImg: "Tenth slide First caroussel",
      },
      {
        id: 10,
        idSlide: "slice1_slide11",
        imgSrc: "images/slide1_img11.png",
        altImg: "Eleventh slide First caroussel",
      },
      {
        id: 11,
        idSlide: "slice1_slide12",
        imgSrc: "images/slide1_img12.png",
        altImg: "Twelve slide First caroussel",
      },
      {
        id: 12,
        idSlide: "slice1_slide13",
        imgSrc: "images/slide1_img13.png",
        altImg: "Thirteen slide First caroussel",
      },
      {
        id: 13,
        idSlide: "slice1_slide14",
        imgSrc: "images/slide1_img14.png",
        altImg: "Fourteen slide First caroussel",
      },
      {
        id: 14,
        idSlide: "slice1_slide15",
        imgSrc: "images/slide1_img15.png",
        altImg: "Fifteen slide First caroussel",
      },
      {
        id: 15,
        idSlide: "slice1_slide16",
        imgSrc: "images/slide1_img16.png",
        altImg: "Sixteen slide First caroussel",
      },
      {
        id: 16,
        idSlide: "slice1_slide17",
        imgSrc: "images/slide1_img17.png",
        altImg: "Seventeen slide First caroussel",
      },
      {
        id: 17,
        idSlide: "slice1_slide18",
        imgSrc: "images/slide1_img18.png",
        altImg: "Eighteen slide First caroussel",
      },
      {
        id: 18,
        idSlide: "slice1_slide19",
        imgSrc: "images/slide1_img19.png",
        altImg: "Nineteen slide First caroussel",
      },
      {
        id: 19,
        idSlide: "slice1_slide20",
        imgSrc: "images/slide1_img20.png",
        altImg: "Twenty slide First caroussel",
      },
      {
        id: 20,
        idSlide: "slice1_slide21",
        imgSrc: "images/slide1_img21.png",
        altImg: "Twenty-one slide First caroussel",
      },
      {
        id: 21,
        idSlide: "slice1_slide22",
        imgSrc: "images/slide1_img22.png",
        altImg: "Twenty-two slide First caroussel",
      },
    ],
  };
  render() {
    const {
      controls,
      indicators,
      pauseOnHover,
      interval,
      slides,
      nextIcon,
      prevIcon,
    } = this.state;
    return (
      <div className="slide_container">
        <BootstrapCarousel
          controls={controls}
          indicators={indicators}
          pauseOnHover={pauseOnHover}
          interval={interval}
          slides={slides}
          nextIcon={nextIcon}
          prevIcon={prevIcon}
          openVideoModal={this.props.openVideoModal}
        />
        <div className="slide_hover"></div>
      </div>
    );
  }
}

export default SlideChampionships;
