import React, { Component } from "react";
import Media from "react-media";
import ImagesOnLoaded from "react-on-images-loaded";
import "./App.css";
import $ from "jquery";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Preloader from "./components/layout/Preloader";
import Home from "./components/pages/Home";
import Academy from "./components/pages/Academy";
import HomeMobile from "./components/pages/HomeMobile";
import AcademyMobile from "./components/pages/AcademyMobile";

class App extends Component {
  hidePreloader = () => {
    setTimeout(() => {
      $("#main-preloader").fadeOut();
    }, 3550);
  };
  render() {
    return (
      <Router>
        <div className="App">
          <ImagesOnLoaded onLoaded={this.hidePreloader}></ImagesOnLoaded>
          <Preloader />
          <Media query={{ maxWidth: 992 }}>
            {(matches) =>
              matches ? (
                <Switch>
                  <Route exact path="/mobile" component={HomeMobile} />
                  <Route
                    exact
                    path="/academiamobile"
                    component={AcademyMobile}
                  />
                  <Redirect to="/mobile" />
                </Switch>
              ) : (
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/academia" component={Academy} />
                  <Redirect to="/" />
                </Switch>
              )
            }
          </Media>
        </div>
      </Router>
    );
  }
}

export default App;
