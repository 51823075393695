import React, { Component } from "react";
import $ from "jquery";
import Videomodal from "../layout/Videomodal";
import NavigationMobileAcademy from "../layout/NavigationMobileAcademy";
import Section1AcademyMobile from "../sections/Section1AcademyMobile";
import Section2AcademyMobile from "../sections/Section2AcademyMobile";
import Section3AcademyMobile from "../sections/Section3AcademyMobile";
import Section4AcademyMobile from "../sections/Section4AcademyMobile";
import Section5AcademyMobile from "../sections/Section5AcademyMobile";
import Section6AcademyMobile from "../sections/Section6AcademyMobile";
import Section7AcademyMobile from "../sections/Section7AcademyMobile";
import Section8AcademyMobile from "../sections/Section8AcademyMobile";
import Section9AcademyMobile from "../sections/Section9AcademyMobile";

class App extends Component {
  state = {
    wd: {
      ww: 0,
      wh: 0,
      sh: 0,
    },
    scrolled: 0,
    body: $("body"),
    showvideomodal: false,
    iframesrc: "",
  };
  componentDidMount() {
    var st = Math.round(window.pageYOffset);
    this.setState({
      scrolled: st,
    });
    this.updateWindowDimensions();
    this.hashLinkScroll();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    window.removeEventListener("scroll", this.handleScroll);
  }
  updateWindowDimensions = () => {
    var section_height = window.innerHeight;
    let wd = {
      ww: window.innerWidth,
      wh: window.innerHeight,
      sh: section_height,
    };
    this.setState({ wd: wd });
  };
  handleScroll = () => {
    var st = Math.round(window.pageYOffset);
    this.setState({
      scrolled: st,
    });
  };
  openVideoModal = (iframesrc) => {
    this.setState({ showvideomodal: true });
    this.setState({
      iframesrc: iframesrc,
    });
    this.state.body.addClass("overflow-hidden");
  };
  closeVideoModal = () => {
    this.setState({ showvideomodal: false });
    this.setState({
      iframesrc: "",
    });
    this.state.body.removeClass("overflow-hidden");
  };
  hashLinkScroll = () => {
    let hash = window.location.hash;
    if (hash != "") {
      setTimeout(() => {
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top,
          },
          500
        );
      }, 0);
    }
  };
  render() {
    return (
      <div
        className="big_container_academy"
        style={{ width: this.state.wd.ww, height: this.state.wd.wh }}
      >
        <NavigationMobileAcademy wd={this.state.wd} />
        <div className="main_container">
          <Videomodal
            iframesrc={this.state.iframesrc}
            closeVideoModal={this.closeVideoModal}
            show={this.state.showvideomodal}
          />
          <Section1AcademyMobile
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section2AcademyMobile
            openVideoModal={this.openVideoModal}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section3AcademyMobile
            openVideoModal={this.openVideoModal}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section4AcademyMobile
            openVideoModal={this.openVideoModal}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section5AcademyMobile
            openVideoModal={this.openVideoModal}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section6AcademyMobile
            openVideoModal={this.openVideoModal}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section7AcademyMobile
            openVideoModal={this.openVideoModal}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section8AcademyMobile
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section9AcademyMobile
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
        </div>
      </div>
    );
  }
}

export default App;
