import React, { Component } from "react";
import NavigationMobile from "../layout/NavigationMobile";
import Section1Mobile from "../sections/Section1Mobile";
import Section2Mobile from "../sections/Section2Mobile";
import Section3Mobile from "../sections/Section3Mobile";
import Section4Mobile from "../sections/Section4Mobile";
import Section5Mobile from "../sections/Section5Mobile";
import Section6Mobile from "../sections/Section6Mobile";
import Section7Mobile from "../sections/Section7Mobile";
import $ from "jquery";

class App extends Component {
  state = {
    wd: {
      ww: 0,
      wh: 0,
      sh: 0,
    },
    navheight: 0,
    scrolled: 0,
  };
  componentDidMount() {
    var st = Math.round(window.pageYOffset);
    this.setState({
      scrolled: st,
    });
    this.updateWindowDimensions();
    this.hashLinkScroll();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    window.removeEventListener("scroll", this.handleScroll);
  }
  updateWindowDimensions = () => {
    var section_height = window.innerHeight * 2;
    let wd = {
      ww: window.innerWidth,
      wh: window.innerHeight,
      sh: section_height,
    };
    this.setState({ wd: wd });
  };
  handleScroll = () => {
    var st = Math.round(window.pageYOffset);
    this.setState({
      scrolled: st,
    });
  };
  hashLinkScroll = () => {
    let hash = window.location.hash;
    if (hash != "") {
      setTimeout(() => {
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top,
          },
          500
        );
      }, 0);
    }
  };
  render() {
    return (
      <div className="big_container">
        <NavigationMobile wd={this.state.wd} />
        <div className="main_container">
          <Section1Mobile
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section2Mobile
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section3Mobile
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section4Mobile
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section5Mobile
            className="schedule"
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section6Mobile
            className="localization"
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section7Mobile
            className="contacts"
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
        </div>
      </div>
    );
  }
}

export default App;
