import React, { Component } from "react";
import { Link } from "react-router-dom";

class Section4Mobile extends Component {
  state = {
    leftOpacity: 0,
  };
  componentDidMount() {
    this.setState({
      translateLeft: -this.props.wd.sh * 0.7,
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.pagescrolled !== prevProps.pagescrolled) {
      var st = this.props.pagescrolled;
      this.setTranslate(st);
    }
  }
  setTranslate = (scroll) => {
    var ele_left = document.querySelector("#section4 .column.left");

    var LeftOpacity;
    var startAnimFactor = 0.3 * this.props.wd.wh;

    var ele_offsettop_left = ele_left.offsetTop;
    /*** set opacity Animation */
    var sectionScrollPercentageWithFactor =
      (100 * (scroll - (ele_offsettop_left - startAnimFactor))) /
      ele_left.clientHeight;
    if (scroll > ele_offsettop_left - startAnimFactor) {
      LeftOpacity =
        1 - sectionScrollPercentageWithFactor * 0.05 > 0
          ? 1 - sectionScrollPercentageWithFactor * 0.05
          : 0;
    } else {
      LeftOpacity = 1;
    }
    this.setState({
      leftOpacity: LeftOpacity,
    });
  };
  render() {
    return (
      <div
        id="section4"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
          height: this.props.wd.sh,
        }}
      >
        <div
          className="column left"
          style={{
            backgroundImage: "url(images/section3_background.jpg)",
          }}
        >
          <div
            className="backgroundImage"
            style={{
              backgroundColor: `rgba(255, 255, 255, ${this.state.leftOpacity})`,
            }}
          ></div>
          <img
            alt="Imagem Jiu Jiteiros"
            className="pawn opacity_animation"
            src="images/pawn2.png"
            style={{
              opacity: this.state.leftOpacity,
            }}
          />
        </div>
        <div
          className="column right"
          style={{
            backgroundColor: `rgba(0, 0, 0, 1)`,
          }}
        >
          <div className="content">
            <img
              alt="Imagem Título Academia"
              className="title"
              src="images/section4_whitetitle.png"
            />
            <div className="text_content">
              <p>
                A academia começou em 2015. Abriu nos Bombeiros de Alcabideche
                no início da época com o Professor Nelson Lopes. Desde essa
                altura a equipa tem vindo a crescer.
                <br /> Os preços podem variar entre 45€ mensais ou 52,5€ para
                quem gosta de complementar o treino com ginásio. Aqui tentamos
                oferecer as melhores condições para os nossos atletas.
              </p>
              <Link to="/academia#section4_anchor">
                <div className="btn from-bottom">
                  <p>Saber mais</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Section4Mobile;
