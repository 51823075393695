import React, { Component } from "react";
import BootstrapCarousel from "./BootstrapCarousel";

class SlideEvents3 extends Component {
  state = {
    controls: true,
    indicators: true,
    pauseOnHover: false,
    interval: 3000,
    nextIcon: <img alt="seta slide esquerda" src="images/arrow_right.png" />,
    prevIcon: <img alt="seta slide direita" src="images/arrow_left.png" />,
    slides: [
      {
        id: 0,
        idSlide: "slide4_img1",
        imgSrc: "images/slide4_img1.png",
        altImg: "First slide Fourth caroussel",
      },
      {
        id: 1,
        idSlide: "slide4_img2",
        imgSrc: "images/slide4_img2.png",
        altImg: "Second slide Fourth caroussel",
      },
      {
        id: 2,
        idSlide: "slide4_img3",
        imgSrc: "images/slide4_img3.png",
        altImg: "Third slide Fourth caroussel",
      },
      {
        id: 3,
        idSlide: "slide4_img4",
        imgSrc: "images/slide4_img4.png",
        altImg: "Fourth slide Fourth caroussel",
      },
    ],
  };
  render() {
    const {
      controls,
      indicators,
      pauseOnHover,
      interval,
      slides,
      nextIcon,
      prevIcon,
    } = this.state;
    return (
      <div className="slide_container">
        <BootstrapCarousel
          controls={controls}
          indicators={indicators}
          pauseOnHover={pauseOnHover}
          interval={interval}
          slides={slides}
          nextIcon={nextIcon}
          prevIcon={prevIcon}
          openVideoModal={this.props.openVideoModal}
        />
        <div className="slide_hover"></div>
      </div>
    );
  }
}

export default SlideEvents3;
