import React, { Component } from "react";
import SlideEvents3 from "../layout/SlideEvents3";

class Section5AcademyMobile extends Component {
  render() {
    return (
      <div
        id="Section5Academy"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
        }}
      >
        <div className="column left section_dif_mobile">
          <div className="content">
            <img
              alt="Imagem do Título Professor"
              className="title"
              src="images/section5academy_lefttitle.png"
            />
            <p>
              A Academia Nelson Lopes já tem finalmente, camisolas oficiais. Não
              parece pelos tons cinzentos do site mas, são lindas e vistosas.
              Nada como vir, pessoalmete vê-las. Esperamos por si.
            </p>
          </div>
        </div>
        <div className="column right" style={{ height: this.props.wd.sh }}>
          <SlideEvents3 openVideoModal={this.props.openVideoModal} />
        </div>
      </div>
    );
  }
}

export default Section5AcademyMobile;
