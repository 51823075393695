import React, { Component } from "react";
import BootstrapCarousel from "./BootstrapCarousel";

class SlideEvents1 extends Component {
  state = {
    controls: true,
    indicators: true,
    pauseOnHover: false,
    interval: 3000,
    nextIcon: <img alt="seta slide esquerda" src="images/arrow_right.png" />,
    prevIcon: <img alt="seta slide direita" src="images/arrow_left.png" />,
    slides: [
      {
        id: 0,
        idSlide: "slide2_img1",
        imgSrc: "images/slide2_img1.png",
        altImg: "First slide Second caroussel",
      },
      {
        id: 3,
        idSlide: "slide2_img4",
        imgSrc: "images/slide2_img4.png",
        altImg: "Fourth slide Second caroussel",
      },
      {
        id: 5,
        idSlide: "slide2_img6",
        imgSrc: "images/slide2_img6.png",
        altImg: "Sixsth slide Second caroussel",
      },
      {
        id: 6,
        idSlide: "slide2_img7",
        imgSrc: "images/slide2_img7.png",
        altImg: "Seventh slide Second caroussel",
        video: "https://www.youtube.com/embed/RTzrqS7IFZE?autoplay=1",
      },
      {
        id: 7,
        idSlide: "slide2_img8",
        imgSrc: "images/slide2_img8.png",
        altImg: "Eigth slide Second caroussel",
        video: "https://www.youtube.com/embed/4gM8_mDK3Uw?autoplay=1",
      },
      {
        id: 8,
        idSlide: "slide2_img9",
        imgSrc: "images/slide2_img9.png",
        altImg: "Nineth slide Second caroussel",
        video: "https://www.youtube.com/embed/6VM-Ip0SNes?autoplay=1",
      },
      {
        id: 9,
        idSlide: "slide2_img10",
        imgSrc: "images/slide2_img10.png",
        altImg: "Tenth slide Second caroussel",
        video: "https://www.youtube.com/embed/LxaCexqYFu0?autoplay=1",
      },
    ],
  };
  render() {
    const {
      controls,
      indicators,
      pauseOnHover,
      interval,
      slides,
      nextIcon,
      prevIcon,
    } = this.state;
    return (
      <div className="slide_container">
        <BootstrapCarousel
          controls={controls}
          indicators={indicators}
          pauseOnHover={pauseOnHover}
          interval={interval}
          slides={slides}
          nextIcon={nextIcon}
          prevIcon={prevIcon}
          openVideoModal={this.props.openVideoModal}
        />
        <div className="slide_hover"></div>
      </div>
    );
  }
}

export default SlideEvents1;
