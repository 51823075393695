import React, { Component } from "react";
import "./Preloader.css";

class Preloader extends Component {
  render() {
    return (
      <div id="main-preloader" className="main-preloader">
        <div className="preloader_logos_container">
          <img
            alt="Logo Pound for Pound"
            className="preloader_logo preloader_logo_pound"
            src="images/logo_pound.svg"
          />
          <img
            alt="Logo Nelson Lopes Academy"
            className="preloader_logo preloader_logo_nelson"
            src="images/logo_nelson.svg"
          />
        </div>
      </div>
    );
  }
}

export default Preloader;
