import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Navigation.css";
import $ from "jquery";

class NavigationMobile extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.checkScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.checkScroll);
  }
  wScroll = (ele) => {
    return $(window).scrollTop();
  };
  checkScroll = (ele) => {
    var links = document.querySelectorAll("nav li > .nav_ancor"),
      offsets = [],
      id;
    var self = this;
    links.forEach(function (i, v) {
      id = $(i).attr("href").replace("#", "");

      offsets.push($("#" + id).offset().top - self.props.wd.wh / 2);
      if (v === links.length - 1) {
        self.toggleNav(offsets);
      }
    });
  };
  toggleNav = (offsets) => {
    var $nav = $("nav"),
      _active = "active";
    var self = this;
    $.each(offsets, function (i, v) {
      if (self.wScroll() < offsets[0]) {
        $nav.find("." + _active).removeClass(_active);
      }

      if (
        (self.wScroll() > v && self.wScroll() < offsets[i + 1]) ||
        (self.wScroll() > v && offsets[i + 1] == null)
      ) {
        var $elem = $nav.find("li > a:eq(" + i + ")");

        $nav.find("." + _active).removeClass(_active);
        $elem.addClass(_active);
      }
    });
  };
  handleClickMenuButton = (e) => {
    var $header = $(".header"),
      _open = "open-mobile";

    if (!$header.hasClass(_open)) $header.addClass(_open);
    else $header.removeClass(_open);
  };
  handleClickNavLinks = (e) => {
    e.preventDefault();
    var id = e.target.getAttribute("href").replace("#", "");
    var offset = $("#" + id).offset().top;

    $("html, body").animate(
      {
        scrollTop: offset,
      },
      500
    );
    if ($(".header").hasClass("open-mobile")) $("#bt-menu").click();
    return false;
  };
  render() {
    return (
      <div className="header">
        <div className="container">
          <nav>
            <button
              id="bt-menu"
              className="logo ir bt-menu"
              onClick={this.handleClickMenuButton}
            >
              <img
                alt="Logo Nelson Lopes Academy"
                src="images/logo_nelson.svg"
              />
            </button>
            <ul>
              <li>
                <a
                  className="nav_ancor"
                  href="section2"
                  onClick={this.handleClickNavLinks}
                >
                  JIU JITSU
                </a>
              </li>
              <li>
                <a
                  className="nav_ancor"
                  href="section3"
                  onClick={this.handleClickNavLinks}
                >
                  PROFESSOR
                </a>
              </li>
              <li>
                <a
                  className="nav_ancor"
                  href="section4"
                  onClick={this.handleClickNavLinks}
                >
                  ACADEMIA
                </a>
              </li>
              <li>
                <a
                  className="nav_ancor"
                  href="section5"
                  onClick={this.handleClickNavLinks}
                >
                  HORÁRIOS
                </a>
              </li>
              <li>
                <a
                  className="nav_ancor"
                  href="section6"
                  onClick={this.handleClickNavLinks}
                >
                  LOCALIZAÇÃO
                </a>
              </li>
              <li>
                <a
                  className="nav_ancor"
                  href="section7"
                  onClick={this.handleClickNavLinks}
                >
                  CONTACTOS
                </a>
              </li>
              <li className="other_page_nav">
                <div>
                  <Link to="/academiamobile">EQUIPA</Link>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default NavigationMobile;
