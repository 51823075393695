import React, { Component } from "react";
import classNames from "classnames";
import "./Videomodal.css";

class Videomodal extends Component {
  state = {
    show: false
  };
  componentDidMount() {
    this.setState({ show: this.props.show });
  }
  render() {
    var videoModalClasses = classNames({
      video_modal: true,
      show: this.props.show ? true : false
    });
    return (
      <div className={videoModalClasses}>
        <button
          className="closemodal"
          onClick={this.props.closeVideoModal}
        ></button>
        <div className="frame">
          <iframe
            allow="autoplay; fullscreen"
            title="introvideo"
            src={this.props.iframesrc}
            frameBorder="0"
            scrolling="no"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
          ></iframe>
        </div>
      </div>
    );
  }
}
export default Videomodal;
