import React, { Component } from "react";
import $ from "jquery";
import Videomodal from "../layout/Videomodal";
import NavigationAcademy from "../layout/NavigationAcademy";
import Section1Academy from "../sections/Section1Academy";
import Section2Academy from "../sections/Section2Academy";
import Section3Academy from "../sections/Section3Academy";
import Section4Academy from "../sections/Section4Academy";
import Section5Academy from "../sections/Section5Academy";
import Section6Academy from "../sections/Section6Academy";
import Section7Academy from "../sections/Section7Academy";
import Section8Academy from "../sections/Section8Academy";
import Section9Academy from "../sections/Section9Academy";

class Academy extends Component {
  state = {
    wd: {
      ww: 0,
      wh: 0,
      sh: 0,
    },
    navheight: 0,
    scrolled: 0,
    body: $("body"),
    showvideomodal: false,
    iframesrc: "",
  };
  componentDidMount() {
    var st = Math.round(window.pageYOffset);
    this.setState({
      scrolled: st,
    });
    this.updateWindowDimensions();
    this.hashLinkScroll();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    window.removeEventListener("scroll", this.handleScroll);
  }
  updateWindowDimensions = () => {
    var section_height = window.innerHeight - this.state.navheight + 1;
    let wd = {
      ww: window.innerWidth,
      wh: window.innerHeight,
      sh: section_height,
    };
    this.setState({ wd: wd });
  };
  handleScroll = () => {
    var st = Math.round(window.pageYOffset);
    this.setState({
      scrolled: st,
    });
  };
  handleNavHeight = (el_height) => {
    this.setState({ navheight: el_height / 2 });
    let wd = {
      ww: window.innerWidth,
      wh: window.innerHeight,
      sh: window.innerHeight - el_height / 2 + 1,
    };
    this.setState({ wd: wd });
  };
  openVideoModal = (iframesrc) => {
    this.setState({ showvideomodal: true });
    this.setState({
      iframesrc: iframesrc,
    });
    this.state.body.addClass("overflow-hidden");
  };
  closeVideoModal = () => {
    this.setState({ showvideomodal: false });
    this.setState({
      iframesrc: "",
    });
    this.state.body.removeClass("overflow-hidden");
  };
  hashLinkScroll = () => {
    let hash = window.location.hash;
    if (hash != "") {
      setTimeout(() => {
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top,
          },
          500
        );
      }, 0);
    }
  };
  render() {
    return (
      <div
        className="big_container_academy"
        style={{ width: this.state.wd.ww, height: this.state.wd.wh }}
      >
        <NavigationAcademy
          wd={this.state.wd}
          handleNavHeight={this.handleNavHeight}
        />
        <div className="main_container">
          <Videomodal
            iframesrc={this.state.iframesrc}
            closeVideoModal={this.closeVideoModal}
            show={this.state.showvideomodal}
          />
          <Section1Academy
            navheight={this.state.navheight}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section2Academy
            navheight={this.state.navheight}
            openVideoModal={this.openVideoModal}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section3Academy
            navheight={this.state.navheight}
            openVideoModal={this.openVideoModal}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section4Academy
            navheight={this.state.navheight}
            openVideoModal={this.openVideoModal}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section5Academy
            navheight={this.state.navheight}
            openVideoModal={this.openVideoModal}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section6Academy
            navheight={this.state.navheight}
            openVideoModal={this.openVideoModal}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section7Academy
            navheight={this.state.navheight}
            openVideoModal={this.openVideoModal}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section8Academy
            navheight={this.state.navheight}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
          <Section9Academy
            navheight={this.state.navheight}
            pagescrolled={this.state.scrolled}
            wd={this.state.wd}
          />
        </div>
      </div>
    );
  }
}

export default Academy;
